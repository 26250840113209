<template>
	<div class="partial partial--auth partial--auth-login auth-login">
		<div class="auth-login__header">
		</div>
		<div class="auth-login__body">
			<card v-if="currentStep == LoginStep.Login">
				<form-login />
			</card>
			<card v-if="currentStep == LoginStep.Location">
				<form-login-location />
			</card>
			<card v-if="currentStep == LoginStep.Twofactor">
				<form-login-mfa />
			</card>
		</div>
	</div>
</template>

<script>
import Card from '@/components/Card'
import FormLogin from '@/components/FormLogin'
import FormLoginLocation from '@/components/FormLoginLocation'
import FormLoginMfa from '@/components/FormLoginMfa'
import { LoginStep } from '@/lib/enums'

export default {
	name: 'PartialAuthLogin',
	components: {
		Card,
		FormLogin,
		FormLoginLocation,
		FormLoginMfa,
	},
	computed: {
		currentStep() {
			const step = this.$store.getters['user/loginStep']
			return step
		},
		LoginStep() {
			return LoginStep
		},
	},
}
</script>

<style scoped lang="scss">

</style>
