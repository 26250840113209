<template>
	<form class="form form-login" @submit.prevent.stop="submitForm(formFields)">
		<div class="form__loading" v-if="isLoading">
			<loading-spinner />
		</div>

		<div class="form-section form-section--centered">
			<img src="@/assets/qp_logo.svg" alt="Quick Patient" class="form__logo" />

			<div class="form-row">
				<text-field class="form__field" label="Email Address" id="login_email" name="email" type="email" autocomplete="email" :required="true" v-model="fields.email">
					<template slot="pre"><i aria-hidden="true" class="material-icons mdc-text-field__icon">email</i></template>
				</text-field>
			</div>
			<div class="form-row">
				<text-field class="form__field" label="Password" id="login_password" name="password" type="password" autocomplete="password" :required="true" v-model="fields.password">
					<template slot="pre"><i class="material-icons mdc-text-field__icon">lock</i></template>
				</text-field>
			</div>

			<div class="form-row alert alert--fail" v-if="error" v-html="error"></div>

			<div class="form__actions form__actions--vertical">
				<button class="mdc-button mdc-button--primary mdc-button--unelevated mdc-button--large mdc-button--full form__action" id="submit_button" type="submit" :disabled="isLoading">
					<span class="mdc-button__ripple"></span>
					<span class="mdc-button__label">Sign In</span>
				</button>
				<button class="mdc-button mdc-button--secondary mdc-button--full form__action" type="button" @click="lostPasswordConfirm(fields)">
					<span class="mdc-button__ripple"></span>
					<span class="mdc-button__label">Forgot Password?</span>
				</button>
			</div>
		</div>

	</form>
</template>

<script>
const recaptchaKey = process.env.RECAPTCHA_KEY || process.env.VUE_APP_RECAPTCHA_KEY

import LoadingSpinner from '@/components/LoadingSpinner'
import TextField from '@/components/TextField'
import { RecaptchaVerifier } from 'firebase/auth'
import { FB } from '@/lib/fb'

export default {
	name: 'FormLogin',
	components: {
		LoadingSpinner,
		TextField,
	},
	data: () => ({
		fields: {
			email: ``,
			password: ``,
		},
	}),
	computed: {
		isLoading() {
			return this.$store.getters['misc/loading']
		},
		error() {
			return this.$store.getters['misc/error']
		},
		formFields() {
			const { redirect_uri } = this.$route.query
			const { email, password } = this.fields

			return {
				email,
				password,
				redirect_uri,
			}
		},
	},
	methods: {
		async submitForm(fields) {
			try {
				await this.$store.dispatch('user/login', fields)
				// this.$router.push({ name: 'main', })
			} catch (error) {
				console.error(error)
			}
		},
		lostPasswordConfirm(fields) {
			this.$confirm(`We will send you an email with password reset instructions.`,
				() => {
					this.lostPassword(fields)
				},
				{
					title: `Send Password Reset?`,
					acceptLabel: `Yes, Send`,
				})
		},
		async lostPassword(fields) {
			const { email } = fields
			if (!email) {
				return this.$store.dispatch('misc/setError', new Error(`Please enter an email address`))
			}

			await this.$store.dispatch('user/recover', email)
			this.$notice(`An email has been sent with your password recovery instructions.<br> Please check your spam folder if you are having trouble locating it.`, undefined, {
				timeout: -1,
			})
		},

	},
}
</script>

<style scoped lang="scss">
.form {
	&__logo {
		@include modules.gutter('margin-bottom');
	}
}
</style>
