<template>
	<form class="form form-login" @submit.prevent.stop="submitForm(fields)">
		<div class="form__loading" v-if="isLoading">
			<loading-spinner />
		</div>

		<div class="form-section form-section--centered">
			<img src="@/assets/qp_logo.svg" alt="Quick Patient" class="form__logo" />

			<h3 class="form__title">Two-Factor Authentication</h3>
			<p class="form__description">
				In order to protect patient information, we require multiple forms of authentication to access your account.
			</p>
			<div class="form-row" v-if="isTotp && login.otpUri">
				<p class="form__description">Scan the QR code below in your authentication app (such as Google Authenticator) to register your two-factor authentication method.</p>
				<div class="form__qr" v-html="totpQr" />
			</div>

			<p class="form__description">
				<template v-if="isHotp">Please enter the verification code sent to your phone number below.</template>
				<template v-if="isTotp">Please enter the verification code from your authenticator app below.</template>
			</p>
			<h3 class="form__title" v-if="isHotp">{{ phone }}</h3>

			<div class="form-row form-row--col-6">
				<text-field inputmode="numeric" pattern="[0-9]" maxlength="1" class="form__field form__field--xl" v-model="fields.digits[0]" @keyup="nextDigit" @paste.prevent="onPaste" />
				<text-field inputmode="numeric" pattern="[0-9]" maxlength="1" class="form__field form__field--xl" v-model="fields.digits[1]" @keyup="nextDigit" />
				<text-field inputmode="numeric" pattern="[0-9]" maxlength="1" class="form__field form__field--xl" v-model="fields.digits[2]" @keyup="nextDigit" />
				<text-field inputmode="numeric" pattern="[0-9]" maxlength="1" class="form__field form__field--xl" v-model="fields.digits[3]" @keyup="nextDigit" />
				<text-field inputmode="numeric" pattern="[0-9]" maxlength="1" class="form__field form__field--xl" v-model="fields.digits[4]" @keyup="nextDigit" />
				<text-field inputmode="numeric" pattern="[0-9]" maxlength="1" class="form__field form__field--xl" v-model="fields.digits[5]" />
			</div>

			<div class="form-row alert alert--fail" v-if="error" v-html="error"></div>

			<div class="form__actions form__actions--vertical">
				<button class="mdc-button mdc-button--primary mdc-button--unelevated mdc-button--large mdc-button--full form__action" id="submit_button" type="submit" :disabled="isLoading || isDisabled">
					<span class="mdc-button__ripple"></span>
					<span class="mdc-button__label">Verify</span>
				</button>
				<button class="mdc-button mdc-button--primary form__action" id="resend_button" type="button" :disabled="isLoading" @click="resend">
					<span class="mdc-button__ripple"></span>
					<span class="mdc-button__label">Resend Code</span>
				</button>
			</div>
		</div>

	</form>
</template>

<script>
import LoadingSpinner from '@/components/LoadingSpinner'
import TextField from '@/components/TextField'
import { OtpMethod } from '@/lib/enums'
import qrcode from 'qrcode-generator'

export default {
	name: 'FormLogin',
	components: {
		LoadingSpinner,
		TextField,
	},
	data: () => ({
		fields: {
			code: ``,
			secret: ``,
			digits: [],
		},
	}),
	computed: {
		isLoading() {
			return this.$store.getters['misc/loading']
		},
		isDisabled() {
			return this.fields.digits.filter(n => n).length < 6
		},
		error() {
			return this.$store.getters['misc/error']
		},
		login() {
			return this.$store.getters['user/login']
		},
		totpQr() {
			let tag = ''
			const typeNumber = 0
			const errorCorrectionLevel = 'L'
			if (this.login.otpMethod == 'totp' && this.login.otpUri) {
				try {
					const qr = qrcode(typeNumber, errorCorrectionLevel)
					qr.addData(this.login.otpUri)
					qr.make()
					tag = qr.createImgTag(5, 0)

				} catch (error) {
				}

				let uri = this.login.otpUri.split('?')[1]
				let params = new URLSearchParams(uri)
				this.fields.secret = params.get('secret')
			}

			return tag
		},
		phone() {
			return `(***)-***-${this.login.otpPhone}`
		},
		isHotp() {
			return this.login.otpMethod == OtpMethod.hotp
		},
		isTotp() {
			return this.login.otpMethod == OtpMethod.totp
		},
	},
	methods: {
		async submitForm(fields) {
			if (!fields.digits.length == 6) return
			fields.code = fields.digits.join('')
			try {
				await this.$store.dispatch('user/otp', fields)
			} catch (error) {
				console.error(error)
			}
		},
		async resend() {
			try {
				await this.$store.dispatch('user/otp', {})
			} catch (error) {
				console.error(error)
			}
		},
		nextDigit(evt) {
			if (!evt.key.match(/[0-9]/)) return
			let nextInput =  evt.target.parentNode.nextElementSibling.querySelector('input')
			this.$nextTick(() => {
				nextInput.select()
			})
		},
		onPaste(evt) {
			const val = evt.clipboardData.getData('Text')
			if (val) {
				this.fields.digits = val.split('')
			}
		},
	},
}
</script>

<style scoped lang="scss">
.form {
	&__logo {
		@include modules.gutter('margin-bottom');
	}

	&__qr {
		img {
			max-width: 100%;
		}
	}

}
</style>
