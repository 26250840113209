<template>
	<form class="form form--reset reset" @submit.prevent.stop="submitForm(fields)">
		<div class="form__loading" v-if="isLoading">
			<loading-spinner />
		</div>

		<div class="form-section form-section--centered">
			<img src="@/assets/qp_logo.svg" alt="Quick Patient" class="form__logo" />

			<h1 class="form-section__title card__title">Set Your New Password</h1>
			<p class="form-section__description"></p>

			<div class="form-row">
				<text-field class="form__field" label="New Password" name="new_password" type="password" :required="true" v-model="fields.password" />
				<div class="form__hint">
					Your password must be a minimum 8 characters and include three of these four character sets: uppercase, lowercase, numbers or special characters.
				</div>
			</div>

			<div class="form-row">
				<text-field class="form__field" label="Confirm New Password" name="password_confirm" type="password" :required="true" v-model="fields.passwordConfirm" />
			</div>

			<div class="alert alert--fail" v-if="error">
				{{ error }}
			</div>

			<div class="form__actions form__actions--vertical">
				<button class="mdc-button mdc-button--primary mdc-button--unelevated mdc-button--large mdc-button--full form__action" type="submit" :disabled="isLoading">
					<span class="mdc-button__ripple"></span>
					<span class="mdc-button__label">Reset Password</span>
				</button>
				<router-link :to="{ name: 'login' }" class="mdc-button mdc-button--secondary mdc-button--full form__action">
					<span class="mdc-button__ripple"></span>
					<i class="material-icons mdc-button__icon" aria-hidden="true">chevron_left</i>
					<span class="mdc-button__label">Back to Login</span>
				</router-link>
			</div>
		</div>

	</form>
</template>

<script>
import LoadingSpinner from '@/components/LoadingSpinner'
import TextField from '@/components/TextField'

export default {
	name: 'FormReset',
	components: {
		LoadingSpinner,
		TextField,
	},
	data: () => ({
		// error: null,
		fields: {},
		// isLoading: false,
	}),
	computed: {
		error() {
			return this.$store.getters['misc/error']
		},
		isLoading() {
			return this.$store.getters['misc/loading']
		}
	},
	methods: {
		async submitForm(fields) {
			if (!fields.password || !fields.passwordConfirm) {
				return this.$store.dispatch('misc/setError', `All fields are required`)
			}
			if ( !fields.password.match(/[a-z]/g) ) {
				return this.$store.dispatch('misc/setError', `New password must contain lowercase letters`)
			}
			if ( !fields.password.match(/[A-Z]/g) ) {
				return this.$store.dispatch('misc/setError', `New password must contain uppercase letters`)
			}
			if ( !fields.password.match(/[0-9]/g) && !fields.password.match(/[^a-zA-Z\d]/g) ) {
				return this.$store.dispatch('misc/setError', `New password must contain number or special character`)
			}
			if (fields.password.length < 8) {
				return this.$store.dispatch('misc/setError', `New password must be 8 or more characters`)
			}
			if (fields.password !== fields.passwordConfirm) {
				return this.$store.dispatch('misc/setError', 'Password confirmation does not match')
			}

			const { password, passwordConfirm, } = fields
			const { token, } = this.$route.query

			const data = {
				password,
				passwordConfirm,
				token,
			}

			try {
				await this.$store.dispatch('user/reset', data)
				this.$notice(`Your password has been reset. Please log in to continue.`, () => {
					this.$router.push({ name: 'login' })
				})
				this.$router.push({ name: 'login' })
			} catch (error) {
				if (error.message.match(/Authorization token cannot be verified\./ig)) {
					this.$store.dispatch('misc/setError', `Your password authorization token has expired.  Please select "Forgot Password?" on the login screen to request another reset link.`)
				} else {
					this.$store.dispatch('misc/setError', error)
				}
			}
		},
	},
}
</script>

<style scoped lang="scss">

</style>
