<template>
	<div class="partial partial--auth partial--auth-reset auth-reset">
		<div class="auth-login__header">
		</div>
		<div class="auth-login__body">
			<card>
				<form-reset class="auth-reset__form" />
			</card>
		</div>
	</div>
</template>

<script>
import Card from '@/components/Card'
import FormReset from '@/components/FormReset'

export default {
	components: {
		Card,
		FormReset,
	}
}
</script>

<style scoped lang="scss">

</style>
