<template>
	<form class="form form-login" @submit.prevent.stop="submitForm(fields)">
		<div class="form__loading" v-if="isLoading">
			<loading-spinner />
		</div>

		<div class="form-section form-section--centered">
			<img src="@/assets/qp_logo.svg" alt="Quick Patient" class="form__logo" />

			<div class="form-row">
				<select-field class="form__field" v-model="fields.location" id="location_select" label="Select Current Location..." :options="locationOptions" :required="true" v-if="locationOptions.length > 1" />

				<template v-if="showTerminals">
					<select-field class="form__field" v-model="fields.terminal" id="terminal_select" label="Select Computer Terminal..." :options="terminalOptions" :required="true" />
				</template>
			</div>

			<div class="form__actions form__actions--vertical">
				<button class="mdc-button mdc-button--primary mdc-button--unelevated mdc-button--large mdc-button--full form__action" type="submit" :disabled="isLoading">
					<span class="mdc-button__ripple"></span>
					<span class="mdc-button__label">Continue</span>
					<i class="material-icons mdc-button__icon" aria-hidden="true">navigate_next</i>
				</button>
			</div>
		</div>
	</form>
</template>

<script>
import LoadingSpinner from '@/components/LoadingSpinner'
import SelectField from '@/components/SelectField'

export default {
	name: 'FormLoginLocation',
	components: {
		LoadingSpinner,
		SelectField,
	},
	data: () => ({
		isLoading: false,
		fields: {
			location: null,
			terminal: 1,
		},
		terminalOptions: [],
		showTerminals: false,
	}),
	computed: {
		me() {
			return this.$store.getters['user/me']
		},
		formFields() {

		},
		locations() {
			const locations = this.$store.getters['user/locations']
			if (locations && locations.length < 2 && locations[0]) {
				this.fields.location = locations[0].locationId
			}
			return locations
		},
		locationOptions() {
			return this.locations.map(l => {
				return {
					value: l.locationId,
					label: `${l.name}`,
					hint: `${l.address}, ${l.city}, ${l.state}`,
				}
			})
		},
	},
	mounted() {
		this.$store.dispatch('user/getLocations')
	},
	methods: {
		async submitForm(fields) {
			const data = {
				locationId: fields.location,
				terminal: fields.terminal,
				// authUser: this.me,
			}
			try {
				await this.$store.dispatch('user/setLocation', data)
				this.$router.push({ name: 'main', })
			} catch (error) {
				this.$notice(`ERROR: ${error.message || error}`)
			}
		},
	},
	watch: {
		'fields.location'(newVal) {
			let terminals = []
			if (!newVal) return

			const location = this.locations.find(l => l.locationId == newVal)
			if (!location || !location.terminalCount || location.terminalCount < 2) return

			for (let i = 1; i <= location.terminalCount; i++) {
				const code = String.fromCharCode(i + 64)
				terminals.push({ label: `Terminal ${code}`, value: code, })
			}

			this.terminalOptions = terminals
			this.showTerminals = terminals && terminals.length > 1
		},
	}
}
</script>

<style scoped lang="scss">
.form {
	&__logo {
		@include modules.gutter('margin-bottom');
	}
}
</style>
