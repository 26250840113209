<template>
	<div class="view view--auth auth">
		<div class="auth__body">
			<router-view class="auth__content" name="content" />
		</div>
	</div>
</template>

<script>

export default {
	name: 'ViewAuth',
	metaInfo: () => ({
		title: `Login`,
	})
}
</script>

<style scoped lang="scss">
.auth {
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
	min-height: 100vh;
	width: 100%;

	&__body {
		border-top: 1px solid modules.color_('primary');
		@include modules.gutter('border-top-width', 0.5);
		align-items: center;
		display: flex;
		flex: 1;
		flex-direction: column;
		justify-content: center;

		.partial {
			max-width: 480px;
			width: 100%;
		}
	}
}

</style>
